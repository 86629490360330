var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.session)?_c('div',{staticClass:"modal slideIn"},[_c('div',{staticClass:"modal-header"},[_c('i',{staticClass:"dashboard-icon icon-session"}),_c('h2',[_vm._v("Session")]),_c('div',{staticClass:"right-aligned"},[_c('button',{staticClass:"go-back",attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.viewPrevious()}}},[_c('i',{staticClass:"dashboard-icon icon-triangle-left"}),_vm._v("Go back ")]),_c('button',{staticClass:"of-modal-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeSessionModal()}}},[_c('i',{staticClass:"dashboard-icon icon-close"})])])]),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"row"},[(this.campaign)?_c('div',{staticClass:"col-sm-12 modal-top"},[_c('h2',[_vm._v(" Session to "+_vm._s(_vm._f("toLowerCase")(_vm._f("humanReadableServiceType")(this.session.serviceType)))+" ")])]):_c('div',{staticClass:"col-sm-12 modal-top"},[_c('h2',[_vm._v("Session")])]),_c('div',{staticClass:"col-sm-4 modal-left"},[_c('div',{staticClass:"uik-widget__wrapper uik-widget__wrapper-top session-information",class:{
              blue: this.session.status === 'Processing',
              green: this.session.status === 'Completed',
              red: this.session.status === 'Failed',
              grey: this.session.status === 'Expired',
            }},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v(" Status: "),_c('span',{class:{
                    blue: this.session.status === 'Processing',
                    green: this.session.status === 'Completed',
                    red: this.session.status === 'Failed',
                    grey: this.session.status === 'Expired',
                  }},[_vm._v(_vm._s(this.session.status))])])]),_c('table',{staticClass:"uik-widget__table_info"},[_c('tbody',[(this.session.createdTs)?_c('tr',[_c('td',[_vm._v("Created")]),_c('td',{domProps:{"innerHTML":_vm._f("formatTime")(this.session.createdTs)}})]):_vm._e(),(this.session.completedTs)?_c('tr',[_c('td',[_vm._v("Completed")]),_c('td',{domProps:{"innerHTML":_vm._f("formatTime")(this.session.completedTs)}})]):_vm._e(),_c('tr',[_c('td',[_vm._v("Service type")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("humanReadableServiceType")(this.session.serviceType))+" ")])]),(
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.communicationCollectionGuid
                  )?_c('tr',[_c('td',[_vm._v("Communication")]),_c('td',[_c('a',{attrs:{"href":_vm.getCommunicationLink,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" Open communication ")])])]):_vm._e(),(this.campaign)?_c('tr',[_c('td',[_vm._v("Campaign")]),(this.$root.user.role === 'MerchantAdmin')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'campaigns',
                        query: {
                          page: 'onboarding_app',
                          view: 'campaigns',
                          guid: this.session.campaignGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.campaign.name)+" ")])],1):(this.$root.user.role === 'AgencyManager')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'agency-campaigns',
                        query: {
                          merchantId: this.session.merchantId,
                          view: 'campaigns',
                          guid: this.session.campaignGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.campaign.name)+" ")])],1):_c('td',[_vm._v(" "+_vm._s(this.campaign.name)+" ")])]):_vm._e(),(this.workflow)?_c('tr',[_c('td',[_vm._v("Workflow")]),(this.$root.user.role === 'MerchantAdmin')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'workflows',
                        query: {
                          page: 'onboarding_app',
                          view: 'workflows',
                          guid: this.session.workflowGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.workflow.name)+" ")])],1):(this.$root.user.role === 'AgencyManager')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'agency-workflows',
                        query: {
                          merchantId: this.session.merchantId,
                          view: 'workflows',
                          guid: this.session.workflowGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.workflow.name)+" ")])],1):_c('td',[_vm._v(" "+_vm._s(this.workflow.name)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Lead")]),(this.$root.user.role === 'MerchantAdmin')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'leads',
                        query: {
                          page: 'onboarding_app',
                          view: 'leads',
                          guid: this.session.leadGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.session.externalLeadId)+" ")])],1):(this.$root.user.role === 'AgencyManager')?_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'agency-leads',
                        query: {
                          merchantId: this.session.merchantId,
                          view: 'leads',
                          guid: this.session.leadGuid,
                        },
                      }}},[_vm._v(" "+_vm._s(this.session.externalLeadId)+" ")])],1):_c('td',[_vm._v(" "+_vm._s(this.session.externalLeadId)+" ")])]),(this.session.merchantId)?_c('tr',[_c('td',[_vm._v("Merchant")]),_c('td',[_vm._v(_vm._s(this.session.merchantId))])]):_vm._e(),(this.session.agencyId)?_c('tr',[_c('td',[_vm._v("Agency")]),_c('td',[_vm._v(_vm._s(this.session.agencyId))])]):_vm._e(),(
                    (this.$root.user.role === 'MerchantAdmin' ||
                      this.$root.user.role === 'AgencyManager' ||
                      this.$root.user.role === 'SeniorAgent') &&
                    this.user
                  )?_c('tr',[_c('td',[_vm._v("User")]),_c('td',[_vm._v(_vm._s(this.user.name))])]):_vm._e()])])]),(this.$root.user.role !== 'JuniorAgent')?[_c('div',{staticClass:"uik-widget__wrapper uik-widget__wrapper-bottom"},[(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.serviceType === 'ChangePaymentMethod' &&
                  this.paymentSession.paymentMethodGuidResult &&
                  this.session.paymentMethodData
                )?_c('div',[_c('div',{staticClass:"sub-title"},[_c('h4',[_vm._v("Payment method")]),_c('a',{staticClass:"uik-btn__base get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._v("Open")])]),(
                    this.session.paymentMethodData.paymentMethodType === 'Card'
                  )?_c('a',{staticClass:"uik-entity-sublist get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._m(0),_c('span',{staticClass:"uik-widget-link__title"},[_vm._v("Card")])]):(
                    this.session.paymentMethodData.paymentMethodType ===
                      'MobilePaySubscriptions' ||
                    this.session.paymentMethodData.paymentMethodType ===
                      'MobilePayOnline'
                  )?_c('a',{staticClass:"uik-entity-sublist get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._m(1),_c('span',{staticClass:"uik-widget-link__title"},[_vm._v("MobilePay")])]):(
                    this.session.paymentMethodData.paymentMethodType ===
                    'Betalingsservice'
                  )?_c('a',{staticClass:"uik-entity-sublist get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._m(2),_c('span',{staticClass:"uik-widget-link__title"},[_vm._v("Betalingsservice")])]):(
                    this.session.paymentMethodData.paymentMethodType === 'SMS'
                  )?_c('a',{staticClass:"uik-entity-sublist get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._m(3),_c('span',{staticClass:"uik-widget-link__title"},[_vm._v("SMS")])]):(
                    this.session.paymentMethodData.paymentMethodType === 'Test'
                  )?_c('a',{staticClass:"uik-entity-sublist get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_vm._m(4),_c('span',{staticClass:"uik-widget-link__title"},[_vm._v("Test")])]):_vm._e()]):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.serviceType === 'ChangePaymentMethod' &&
                  this.paymentSession.paymentMethodGuidResult &&
                  this.session.paymentMethodData
                )?_c('div',{staticClass:"h-divider"}):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  ((this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid) ||
                    this.paymentSession.subscriptionGuidResult) &&
                  (this.session.serviceType === 'AgreementUpdate' ||
                    this.session.serviceType === 'CreateAddOn' ||
                    this.session.serviceType === 'ChangePaymentMethod')
                )?_c('div',{staticClass:"sub-title"},[_c('h4',[_vm._v("Connections")])]):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  ((this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid) ||
                    this.paymentSession.subscriptionGuidResult) &&
                  (this.session.serviceType === 'AgreementUpdate' ||
                    this.session.serviceType === 'CreateAddOn' ||
                    this.session.serviceType === 'ChangePaymentMethod')
                )?_c('ul',{staticClass:"list-bullets"},[_c('li',[_c('a',{staticClass:"uik-btn__base button button-secondary get_single",attrs:{"href":_vm.getSubscriptionLink,"target":"_blank"}},[_vm._v("See subscription")])])]):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  ((this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid) ||
                    this.paymentSession.subscriptionGuidResult) &&
                  (this.session.serviceType === 'AgreementUpdate' ||
                    this.session.serviceType === 'CreateAddOn' ||
                    this.session.serviceType === 'ChangePaymentMethod')
                )?_c('div',{staticClass:"h-divider"}):_vm._e(),(this.session.contactData)?_c('div',{staticClass:"sub-title"},[_c('h4',[_vm._v("Contact information")]),(
                    this.$root.user.role === 'MerchantAdmin' &&
                    (this.session.contactData.contactGuid ||
                      this.paymentSession.contactGuidResult)
                  )?_c('a',{staticClass:"uik-btn__base get_single",attrs:{"href":_vm.getContactLink,"target":"_blank"}},[_vm._v("Open")]):_vm._e()]):_vm._e(),(this.session.contactData)?_c('table',{staticClass:"uik-widget__table_info contact-info-simple"},[_c('tbody',_vm._l((this.session.contactData),function(value,propertyName){return _c('tr',{key:propertyName},[(propertyName !== 'contactGuid')?_c('td',[_vm._v(" "+_vm._s(_vm._f("humanReadableContactProperty")(propertyName))+" ")]):_vm._e(),(propertyName !== 'contactGuid')?_c('td',[_vm._v(_vm._s(value))]):_vm._e()])}),0)]):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.dataSetGuid
                )?_c('div',{staticClass:"h-divider"}):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.dataSetGuid
                )?_c('div',{staticClass:"sub-title"},[_c('h4',[_vm._v("Data Set")]),_c('a',{staticClass:"uik-btn__base get_single",attrs:{"href":_vm.getDataSetLink,"target":"_blank"}},[_vm._v("Open")])]):_vm._e(),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.dataSetGuid
                )?_c('br'):_vm._e()])]:_vm._e()],2),_c('div',{staticClass:"col-sm-8 modal-right"},[(
              this.session.serviceType === 'SignUpSingle' ||
              this.session.serviceType === 'SignUpSingleByGiro3rd' ||
              this.session.serviceType === 'SignUpSingleByInvoice3rd' ||
              this.session.serviceType === 'SignUpSingleByEAN3rd'
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_vm._m(5),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(this.paymentSession.paymentGuidResult)?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[(this.$root.user.role === 'MerchantAdmin')?_c('a',{attrs:{"href":_vm.getPaymentLink,"target":"_blank"}},[_vm._v(_vm._s(this.paymentSession.paymentGuidResult))]):_c('span',[_vm._v(" "+_vm._s(this.paymentSession.paymentGuidResult)+" ")])])]):_vm._e(),_c('tr',[_c('td',{attrs:{"title":"Purpose accounting code"}},[_vm._v("Purpose acc. code")]),_c('td',[_vm._v(" "+_vm._s(this.session.paymentData.purposeAccountingCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Amount")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(this.session.paymentData.amountTotal))+" "+_vm._s(this.session.paymentData.currencyCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Payment required")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(this.session.paymentData.paymentRequired))+" ")])]),_c('tr',[_c('td',[_vm._v("Tax-deductible")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(this.session.paymentData))+" ")])]),(
                    this.$root.user.role !== 'MerchantAdmin' &&
                    this.session.communicationCollectionGuid
                  )?_c('tr',[_c('td',[_vm._v("Communication guid")]),_c('td',[_vm._v(" "+_vm._s(this.session.communicationCollectionGuid)+" ")])]):_vm._e()])])]):_vm._e(),(
              this.session.serviceType === 'SignUpRecurring' ||
              this.session.serviceType === 'SignUpRecurringByBs3rd' ||
              this.session.serviceType === 'SignUpRecurringByGiro3rd' ||
              this.session.serviceType === 'SignUpRecurringByInvoice3rd' ||
              this.session.serviceType === 'SignUpRecurringByEAN3rd' ||
              this.session.serviceType === 'AgreementUpdate' ||
              this.session.serviceType === 'ChangePaymentMethod'
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Agreement details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  (this.session.agreementData.agreementGuid ||
                    this.paymentSession.agreementGuidResult)
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getAgreementLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See agreement ")]):_vm._e()]),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    (this.session.agreementData.agreementGuid ||
                      this.paymentSession.agreementGuidResult) &&
                    this.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),(this.session.agreementData.agreementGuid)?_c('td',[_vm._v(" "+_vm._s(this.session.agreementData.agreementGuid)+" ")]):_c('td',[_vm._v(" "+_vm._s(this.paymentSession.agreementGuidResult)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(this.session.agreementData.name))])]),(this.session.agreementData.description)?_c('tr',[_c('td',[_vm._v("Description")]),_c('td',[_vm._v(_vm._s(this.session.agreementData.description))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Agreement type")]),_c('td',[_vm._v(_vm._s(this.session.agreementData.agreementType))])]),_c('tr',[_c('td',{attrs:{"title":"Purpose accounting code"}},[_vm._v("Purpose acc. code")]),_c('td',[_vm._v(" "+_vm._s(this.session.agreementData.purposeAccountingCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Amount (incl. VAT)")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(this.session.agreementData.amountTotal))+" "+_vm._s(this.session.agreementData.currencyCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Schedule")]),_c('td',[_vm._v(" "+_vm._s(this.getHumanReadableSchedule)+" ")])]),_c('tr',[_c('td',[_vm._v("Payment required")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(this.session.agreementData.paymentRequired))+" ")])]),_c('tr',[_c('td',[_vm._v("Tax-deductible")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(this.session.agreementData.taxDeductable))+" ")])]),(
                    this.$root.user.role !== 'MerchantAdmin' &&
                    this.session.communicationCollectionGuid
                  )?_c('tr',[_c('td',[_vm._v("Communication guid")]),_c('td',[_vm._v(" "+_vm._s(this.session.communicationCollectionGuid)+" ")])]):_vm._e()])])]):_vm._e(),(this.session.serviceType === 'CreateAddOn')?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Add-on details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.addOnData
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getAddOnLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See add-on ")]):_vm._e()]),_vm._l((this.session.addOnData),function(addOnData){return _c('table',{key:addOnData.addOnGuid,staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    addOnData.addOnGuid && _vm.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[_vm._v(" "+_vm._s(addOnData.addOnGuid)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(addOnData.name))])]),_c('tr',[_c('td',[_vm._v("Description")]),_c('td',[_vm._v(_vm._s(addOnData.description))])]),_c('tr',[_c('td',[_vm._v("Purpose acc. code")]),_c('td',[_vm._v(" "+_vm._s(addOnData.purposeAccountingCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Amount")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(addOnData.amountTotal))+" "+_vm._s(_vm.session.agreementData.currencyCode)+" ")])]),_c('tr',[_c('td',[_vm._v("Tax-deductible")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatBoolean")(addOnData.taxDeductable))+" ")])])])])})],2):_vm._e(),(
              this.session.serviceType === 'SignUpRecurring' ||
              this.session.serviceType === 'SubscriptionRestart' ||
              this.session.serviceType === 'SubscriptionExpirationRestart'
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Subscription details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  ((this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid) ||
                    this.paymentSession.subscriptionGuidResult)
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getSubscriptionLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See subscription ")]):_vm._e()]),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    this.paymentSession.subscriptionGuidResult &&
                    this.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[_vm._v(" "+_vm._s(this.paymentSession.subscriptionGuidResult)+" ")])]):_vm._e(),(this.session.subscriptionData.startDate)?_c('tr',[_c('td',[_vm._v("Start date")]),_c('td',[_vm._v(_vm._s(this.session.subscriptionData.startDate))])]):_vm._e(),(this.session.subscriptionData.nextDueDate)?_c('tr',[_c('td',[_vm._v("Next due date")]),_c('td',[_vm._v(_vm._s(this.session.subscriptionData.nextDueDate))])]):_vm._e(),(this.session.subscriptionData.expiresAfterDate)?_c('tr',[_c('td',[_vm._v("Expires after date")]),_c('td',[_vm._v(" "+_vm._s(this.session.subscriptionData.expiresAfterDate)+" ")])]):_vm._e()])])]):_vm._e(),(this.session.serviceType === 'SubscriptionHold' || this.session.serviceType === 'SubscriptionExpirationHold')?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Subscription details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.subscriptionData &&
                  this.session.subscriptionData.subscriptionGuid
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getSubscriptionLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See subscription ")]):_vm._e()]),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid &&
                    this.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[_vm._v(" "+_vm._s(this.session.subscriptionData.subscriptionGuid)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Hold description")]),(this.session.subscriptionData.holdDescription)?_c('td',[_vm._v(" "+_vm._s(this.session.subscriptionData.holdDescription)+" ")]):_c('td',[_vm._v("No description given")])])])])]):_vm._e(),(this.session.serviceType === 'SubscriptionCancel')?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Subscription details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.session.subscriptionData &&
                  this.session.subscriptionData.subscriptionGuid
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getSubscriptionLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See subscription ")]):_vm._e()]),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid &&
                    this.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[_vm._v(" "+_vm._s(this.session.subscriptionData.subscriptionGuid)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Cancel description")]),(this.session.subscriptionData.cancelDescription)?_c('td',[_vm._v(" "+_vm._s(this.session.subscriptionData.cancelDescription)+" ")]):_c('td',[_vm._v("No description given")])])])])]):_vm._e(),(
              this.session.paymentMethodData &&
              (this.session.serviceType === 'SignUpSingle' ||
                this.session.serviceType === 'SignUpRecurring' ||
                this.session.serviceType === 'SignUpRecurringByBs3rd' ||
                this.session.serviceType === 'SignUpSingleByGiro3rd' ||
                this.session.serviceType === 'SignUpRecurringByGiro3rd' ||
                this.session.serviceType === 'SignUpSingleByInvoice3rd' ||
                this.session.serviceType === 'SignUpRecurringByInvoice3rd' ||
                this.session.serviceType === 'SignUpSingleByEAN3rd' ||
                this.session.serviceType === 'SignUpRecurringByEAN3rd' ||
                this.session.serviceType === 'SubscriptionRestart' ||
                this.session.serviceType === 'SubscriptionExpirationRestart' )
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Payment method details")]),(
                  this.$root.user.role === 'MerchantAdmin' &&
                  this.paymentSession.paymentMethodGuidResult
                )?_c('a',{staticClass:"action get_single",attrs:{"href":_vm.getPaymentMethodLink,"target":"_blank"}},[_c('i',{staticClass:"dashboard-icon icon-view"}),_vm._v("See payment method ")]):_vm._e()]),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(
                    this.paymentSession.paymentMethodGuidResult &&
                    this.$root.user.role !== 'MerchantAdmin'
                  )?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[_vm._v(" "+_vm._s(this.paymentSession.paymentMethodGuidResult)+" ")])]):_vm._e(),(
                    this.session.paymentMethodData &&
                    this.session.paymentMethodData.paymentMethodType
                  )?_c('tr',[_c('td',[_vm._v("Type")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("humanReadablePaymentMethodType")(this.session.paymentMethodData.paymentMethodType))+" ")])]):_vm._e()])])]):_vm._e(),(
              this.session.serviceType === 'SignUpRecurring' &&
              this.paymentSession.paymentGuidResult
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_vm._m(6),_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',[(this.paymentSession.paymentGuidResult)?_c('tr',[_c('td',[_vm._v("Guid")]),_c('td',[(this.$root.user.role === 'MerchantAdmin')?_c('a',{attrs:{"href":_vm.getPaymentLink,"target":"_blank"}},[_vm._v(_vm._s(this.paymentSession.paymentGuidResult))]):_c('span',[_vm._v(" "+_vm._s(this.paymentSession.paymentGuidResult)+" ")])])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Amount")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(this.session.paymentData.oneOffAmount))+" "+_vm._s(this.session.paymentData.currencyCode)+" ")])])])])]):_vm._e(),(
              this.session.metaData &&
              Object.keys(this.session.metaData).length > 0
            )?_c('div',{staticClass:"uik-widget__wrapper"},[_vm._m(7),(this.workflow)?_c('table',{staticClass:"uik-widget__table_details"},[_c('tbody',_vm._l((this.session.metaDataFields),function(metaDataField){return _c('tr',{key:metaDataField.uuid},[_c('td',[_vm._v(_vm._s(metaDataField.label))]),(metaDataField.value)?_c('td',[_vm._v(" "+_vm._s(metaDataField.value)+" ")]):_c('td',[_vm._v("N/A")])])}),0)]):_vm._e()]):_vm._e()])])])]):_vm._e(),(_vm.session)?_c('div',{staticClass:"of-modal-bg fadeIn",on:{"click":function($event){return _vm.closeSessionModal()}}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-link__icon pm-card"},[_c('i')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-link__icon pm-mobilepay"},[_c('i')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-link__icon pm-betalingsservice"},[_c('i')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-link__icon pm-sms"},[_c('i')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-link__icon pm-test"},[_c('i')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Payment details")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Payment details")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uik-widget-title__wrapper"},[_c('h3',[_vm._v("Meta data")])])
}]

export { render, staticRenderFns }