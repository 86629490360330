<template>
  <div>
    <!-- Modal -->
    <div v-if="session" class="modal slideIn">
      <!-- Header -->
      <div class="modal-header">
        <i class="dashboard-icon icon-session"></i>
        <h2>Session</h2>
        <div class="right-aligned">
          <button
            type="button"
            class="go-back"
            v-on:click="$root.viewPrevious()"
          >
            <i class="dashboard-icon icon-triangle-left"></i>Go back
          </button>
          <button
            type="button"
            class="of-modal-close"
            v-on:click="closeSessionModal()"
          >
            <i class="dashboard-icon icon-close"></i>
          </button>
        </div>
      </div>
      <!-- Content -->
      <div class="modal-content">
        <div class="row">
          <div v-if="this.campaign" class="col-sm-12 modal-top">
            <h2>
              Session to
              {{
                this.session.serviceType
                  | humanReadableServiceType
                  | toLowerCase
              }}
            </h2>
          </div>
          <div v-else class="col-sm-12 modal-top">
            <h2>Session</h2>
          </div>
          <!-- Left -->
          <div class="col-sm-4 modal-left">
            <div
              class="
                uik-widget__wrapper uik-widget__wrapper-top
                session-information
              "
              v-bind:class="{
                blue: this.session.status === 'Processing',
                green: this.session.status === 'Completed',
                red: this.session.status === 'Failed',
                grey: this.session.status === 'Expired',
              }"
            >
              <div class="uik-widget-title__wrapper">
                <h3>
                  Status:
                  <span
                    v-bind:class="{
                      blue: this.session.status === 'Processing',
                      green: this.session.status === 'Completed',
                      red: this.session.status === 'Failed',
                      grey: this.session.status === 'Expired',
                    }"
                    >{{ this.session.status }}</span
                  >
                </h3>
              </div>
              <table class="uik-widget__table_info">
                <tbody>
                  <tr v-if="this.session.createdTs">
                    <td>Created</td>
                    <td
                      v-bind:inner-html.prop="
                        this.session.createdTs | formatTime
                      "
                    ></td>
                  </tr>
                  <tr v-if="this.session.completedTs">
                    <td>Completed</td>
                    <td
                      v-bind:inner-html.prop="
                        this.session.completedTs | formatTime
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td>Service type</td>
                    <td>
                      {{ this.session.serviceType | humanReadableServiceType }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      this.$root.user.role === 'MerchantAdmin' &&
                      this.session.communicationCollectionGuid
                    "
                  >
                    <td>Communication</td>
                    <td>
                      <a
                        v-bind:href="getCommunicationLink"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open communication
                      </a>
                    </td>
                  </tr>

                  <!-- Campaign with link -->
                  <tr v-if="this.campaign">
                    <td>Campaign</td>
                    <td v-if="this.$root.user.role === 'MerchantAdmin'">
                      <router-link
                        :to="{
                          name: 'campaigns',
                          query: {
                            page: 'onboarding_app',
                            view: 'campaigns',
                            guid: this.session.campaignGuid,
                          },
                        }"
                      >
                        {{ this.campaign.name }}
                      </router-link>
                    </td>
                    <td v-else-if="this.$root.user.role === 'AgencyManager'">
                      <router-link
                        :to="{
                          name: 'agency-campaigns',
                          query: {
                            merchantId: this.session.merchantId,
                            view: 'campaigns',
                            guid: this.session.campaignGuid,
                          },
                        }"
                      >
                        {{ this.campaign.name }}
                      </router-link>
                    </td>
                    <td v-else>
                      {{ this.campaign.name }}
                    </td>
                  </tr>

                  <!-- Workflow with link -->
                  <tr v-if="this.workflow">
                    <td>Workflow</td>
                    <td v-if="this.$root.user.role === 'MerchantAdmin'">
                      <router-link
                        :to="{
                          name: 'workflows',
                          query: {
                            page: 'onboarding_app',
                            view: 'workflows',
                            guid: this.session.workflowGuid,
                          },
                        }"
                      >
                        {{ this.workflow.name }}
                      </router-link>
                    </td>
                    <td v-else-if="this.$root.user.role === 'AgencyManager'">
                      <router-link
                        :to="{
                          name: 'agency-workflows',
                          query: {
                            merchantId: this.session.merchantId,
                            view: 'workflows',
                            guid: this.session.workflowGuid,
                          },
                        }"
                      >
                        {{ this.workflow.name }}
                      </router-link>
                    </td>
                    <td v-else>
                      {{ this.workflow.name }}
                    </td>
                  </tr>

                  <!-- Lead with link -->
                  <tr>
                    <td>Lead</td>
                    <td v-if="this.$root.user.role === 'MerchantAdmin'">
                      <router-link
                        :to="{
                          name: 'leads',
                          query: {
                            page: 'onboarding_app',
                            view: 'leads',
                            guid: this.session.leadGuid,
                          },
                        }"
                      >
                        {{ this.session.externalLeadId }}
                      </router-link>
                    </td>
                    <td v-else-if="this.$root.user.role === 'AgencyManager'">
                      <router-link
                        :to="{
                          name: 'agency-leads',
                          query: {
                            merchantId: this.session.merchantId,
                            view: 'leads',
                            guid: this.session.leadGuid,
                          },
                        }"
                      >
                        {{ this.session.externalLeadId }}
                      </router-link>
                    </td>
                    <td v-else>
                      {{ this.session.externalLeadId }}
                    </td>
                  </tr>

                  <tr v-if="this.session.merchantId">
                    <td>Merchant</td>
                    <td>{{ this.session.merchantId }}</td>
                  </tr>
                  <tr v-if="this.session.agencyId">
                    <td>Agency</td>
                    <td>{{ this.session.agencyId }}</td>
                  </tr>
                  <tr
                    v-if="
                      (this.$root.user.role === 'MerchantAdmin' ||
                        this.$root.user.role === 'AgencyManager' ||
                        this.$root.user.role === 'SeniorAgent') &&
                      this.user
                    "
                  >
                    <td>User</td>
                    <td>{{ this.user.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Connections and ContactData limited from Junior Agents -->
            <template v-if="this.$root.user.role !== 'JuniorAgent'">
              
              <!-- Connections -->
              <div class="uik-widget__wrapper uik-widget__wrapper-bottom">
                <!-- PaymentMethod -->
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.serviceType === 'ChangePaymentMethod' &&
                    this.paymentSession.paymentMethodGuidResult &&
                    this.session.paymentMethodData
                  "
                >
                  <div class="sub-title">
                    <h4>Payment method</h4>
                    <a
                      v-bind:href="getPaymentMethodLink"
                      target="_blank"
                      class="uik-btn__base get_single"
                      >Open</a
                    >
                  </div>

                  <!-- PaymentMethodType, Card -->
                  <a
                    v-bind:href="getPaymentMethodLink"
                    v-if="
                      this.session.paymentMethodData.paymentMethodType === 'Card'
                    "
                    target="_blank"
                    class="uik-entity-sublist get_single"
                  >
                    <div class="uik-widget-link__icon pm-card"><i></i></div>
                    <span class="uik-widget-link__title">Card</span>
                  </a>

                  <!-- PaymentMethodType, MobilePay -->
                  <a
                    v-bind:href="getPaymentMethodLink"
                    v-else-if="
                      this.session.paymentMethodData.paymentMethodType ===
                        'MobilePaySubscriptions' ||
                      this.session.paymentMethodData.paymentMethodType ===
                        'MobilePayOnline'
                    "
                    target="_blank"
                    class="uik-entity-sublist get_single"
                  >
                    <div class="uik-widget-link__icon pm-mobilepay"><i></i></div>
                    <span class="uik-widget-link__title">MobilePay</span>
                  </a>

                  <!-- PaymentMethodType, Betalingsservice -->
                  <a
                    v-bind:href="getPaymentMethodLink"
                    v-else-if="
                      this.session.paymentMethodData.paymentMethodType ===
                      'Betalingsservice'
                    "
                    target="_blank"
                    class="uik-entity-sublist get_single"
                  >
                    <div class="uik-widget-link__icon pm-betalingsservice">
                      <i></i>
                    </div>
                    <span class="uik-widget-link__title">Betalingsservice</span>
                  </a>

                  <!-- PaymentMethodType, SMS -->
                  <a
                    v-bind:href="getPaymentMethodLink"
                    v-else-if="
                      this.session.paymentMethodData.paymentMethodType === 'SMS'
                    "
                    target="_blank"
                    class="uik-entity-sublist get_single"
                  >
                    <div class="uik-widget-link__icon pm-sms"><i></i></div>
                    <span class="uik-widget-link__title">SMS</span>
                  </a>

                  <!-- PaymentMethodType, Test -->
                  <a
                    v-bind:href="getPaymentMethodLink"
                    v-else-if="
                      this.session.paymentMethodData.paymentMethodType === 'Test'
                    "
                    target="_blank"
                    class="uik-entity-sublist get_single"
                  >
                    <div class="uik-widget-link__icon pm-test"><i></i></div>
                    <span class="uik-widget-link__title">Test</span>
                  </a>
                </div>

                <!-- Divider -->
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.serviceType === 'ChangePaymentMethod' &&
                    this.paymentSession.paymentMethodGuidResult &&
                    this.session.paymentMethodData
                  "
                  class="h-divider"
                ></div>

                <!-- Connections -->
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    ((this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid) ||
                      this.paymentSession.subscriptionGuidResult) &&
                    (this.session.serviceType === 'AgreementUpdate' ||
                      this.session.serviceType === 'CreateAddOn' ||
                      this.session.serviceType === 'ChangePaymentMethod')
                  "
                  class="sub-title"
                >
                  <h4>Connections</h4>
                </div>

                <!-- Subscription -->
                <ul
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    ((this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid) ||
                      this.paymentSession.subscriptionGuidResult) &&
                    (this.session.serviceType === 'AgreementUpdate' ||
                      this.session.serviceType === 'CreateAddOn' ||
                      this.session.serviceType === 'ChangePaymentMethod')
                  "
                  class="list-bullets"
                >
                  <li>
                    <a
                      v-bind:href="getSubscriptionLink"
                      target="_blank"
                      class="uik-btn__base button button-secondary get_single"
                      >See subscription</a
                    >
                  </li>
                </ul>

                <!-- Divider -->
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    ((this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid) ||
                      this.paymentSession.subscriptionGuidResult) &&
                    (this.session.serviceType === 'AgreementUpdate' ||
                      this.session.serviceType === 'CreateAddOn' ||
                      this.session.serviceType === 'ChangePaymentMethod')
                  "
                  class="h-divider"
                ></div>

                <!-- ContactData -->
                <div v-if="this.session.contactData" class="sub-title">
                  <h4>Contact information</h4>
                  <a
                    v-if="
                      this.$root.user.role === 'MerchantAdmin' &&
                      (this.session.contactData.contactGuid ||
                        this.paymentSession.contactGuidResult)
                    "
                    v-bind:href="getContactLink"
                    target="_blank"
                    class="uik-btn__base get_single"
                    >Open</a
                  >
                </div>
                <table
                  v-if="this.session.contactData"
                  class="uik-widget__table_info contact-info-simple"
                >
                  <tbody>
                    <tr
                      v-for="(value, propertyName) in this.session.contactData"
                      v-bind:key="propertyName"
                    >
                      <td v-if="propertyName !== 'contactGuid'">
                        {{ propertyName | humanReadableContactProperty }}
                      </td>
                      <td v-if="propertyName !== 'contactGuid'">{{ value }}</td>
                    </tr>
                  </tbody>
                </table>

                <!-- DataSet -->
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.dataSetGuid
                  "
                  class="h-divider"
                ></div>
                <div
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.dataSetGuid
                  "
                  class="sub-title"
                >
                  <h4>Data Set</h4>
                  <a
                    v-bind:href="getDataSetLink"
                    target="_blank"
                    class="uik-btn__base get_single"
                    >Open</a
                  >
                </div>
                <br
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.dataSetGuid
                  "
                />
              </div>

            </template>

          </div>

          <!-- Right -->
          <div class="col-sm-8 modal-right">
            <!-- SignUpSingle -->
            <div
              v-if="
                this.session.serviceType === 'SignUpSingle' ||
                this.session.serviceType === 'SignUpSingleByGiro3rd' ||
                this.session.serviceType === 'SignUpSingleByInvoice3rd' ||
                this.session.serviceType === 'SignUpSingleByEAN3rd'
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Payment details</h3>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr v-if="this.paymentSession.paymentGuidResult">
                    <td>Guid</td>
                    <td>
                      <a
                        v-if="this.$root.user.role === 'MerchantAdmin'"
                        v-bind:href="getPaymentLink"
                        target="_blank"
                        >{{ this.paymentSession.paymentGuidResult }}</a
                      >
                      <span v-else>
                        {{ this.paymentSession.paymentGuidResult }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td title="Purpose accounting code">Purpose acc. code</td>
                    <td>
                      {{ this.session.paymentData.purposeAccountingCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {{ this.session.paymentData.amountTotal | formatAmount }}
                      {{ this.session.paymentData.currencyCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Payment required</td>
                    <td>
                      {{
                        this.session.paymentData.paymentRequired | formatBoolean
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tax-deductible</td>
                    <td>
                      {{ this.session.paymentData | formatBoolean }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      this.$root.user.role !== 'MerchantAdmin' &&
                      this.session.communicationCollectionGuid
                    "
                  >
                    <td>Communication guid</td>
                    <td>
                      {{ this.session.communicationCollectionGuid }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SignUpRecurring / SignUpRecurringByBs3rd / AgreementUpdate / ChangePaymentMethod -->
            <div
              v-if="
                this.session.serviceType === 'SignUpRecurring' ||
                this.session.serviceType === 'SignUpRecurringByBs3rd' ||
                this.session.serviceType === 'SignUpRecurringByGiro3rd' ||
                this.session.serviceType === 'SignUpRecurringByInvoice3rd' ||
                this.session.serviceType === 'SignUpRecurringByEAN3rd' ||
                this.session.serviceType === 'AgreementUpdate' ||
                this.session.serviceType === 'ChangePaymentMethod'
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Agreement details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    (this.session.agreementData.agreementGuid ||
                      this.paymentSession.agreementGuidResult)
                  "
                  v-bind:href="getAgreementLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See agreement
                </a>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-if="
                      (this.session.agreementData.agreementGuid ||
                        this.paymentSession.agreementGuidResult) &&
                      this.$root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td v-if="this.session.agreementData.agreementGuid">
                      {{ this.session.agreementData.agreementGuid }}
                    </td>
                    <td v-else>
                      {{ this.paymentSession.agreementGuidResult }}
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{{ this.session.agreementData.name }}</td>
                  </tr>
                  <tr v-if="this.session.agreementData.description">
                    <td>Description</td>
                    <td>{{ this.session.agreementData.description }}</td>
                  </tr>
                  <tr>
                    <td>Agreement type</td>
                    <td>{{ this.session.agreementData.agreementType }}</td>
                  </tr>
                  <tr>
                    <td title="Purpose accounting code">Purpose acc. code</td>
                    <td>
                      {{ this.session.agreementData.purposeAccountingCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount (incl. VAT)</td>
                    <td>
                      {{
                        this.session.agreementData.amountTotal | formatAmount
                      }}
                      {{ this.session.agreementData.currencyCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Schedule</td>
                    <td>
                      {{ this.getHumanReadableSchedule }}
                    </td>
                  </tr>
                  <tr>
                    <td>Payment required</td>
                    <td>
                      {{
                        this.session.agreementData.paymentRequired
                          | formatBoolean
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tax-deductible</td>
                    <td>
                      {{
                        this.session.agreementData.taxDeductable | formatBoolean
                      }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      this.$root.user.role !== 'MerchantAdmin' &&
                      this.session.communicationCollectionGuid
                    "
                  >
                    <td>Communication guid</td>
                    <td>
                      {{ this.session.communicationCollectionGuid }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- CreateAddOn -->
            <div
              v-if="this.session.serviceType === 'CreateAddOn'"
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Add-on details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.addOnData
                  "
                  v-bind:href="getAddOnLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See add-on
                </a>
              </div>
              <table
                v-for="addOnData in this.session.addOnData"
                v-bind:key="addOnData.addOnGuid"
                class="uik-widget__table_details"
              >
                <tbody>
                  <tr
                    v-if="
                      addOnData.addOnGuid && $root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td>
                      {{ addOnData.addOnGuid }}
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{{ addOnData.name }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ addOnData.description }}</td>
                  </tr>
                  <tr>
                    <td>Purpose acc. code</td>
                    <td>
                      {{ addOnData.purposeAccountingCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {{ addOnData.amountTotal | formatAmount }}
                      {{ session.agreementData.currencyCode }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tax-deductible</td>
                    <td>
                      {{ addOnData.taxDeductable | formatBoolean }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SignUpRecurring / SubscriptionRestart / SubscriptionExpirationRestart -->
            <div
              v-if="
                this.session.serviceType === 'SignUpRecurring' ||
                this.session.serviceType === 'SubscriptionRestart' ||
                this.session.serviceType === 'SubscriptionExpirationRestart'
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Subscription details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    ((this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid) ||
                      this.paymentSession.subscriptionGuidResult)
                  "
                  v-bind:href="getSubscriptionLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See subscription
                </a>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-if="
                      this.paymentSession.subscriptionGuidResult &&
                      this.$root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td>
                      {{ this.paymentSession.subscriptionGuidResult }}
                    </td>
                  </tr>
                  <tr v-if="this.session.subscriptionData.startDate">
                    <td>Start date</td>
                    <td>{{ this.session.subscriptionData.startDate }}</td>
                  </tr>
                  <tr v-if="this.session.subscriptionData.nextDueDate">
                    <td>Next due date</td>
                    <td>{{ this.session.subscriptionData.nextDueDate }}</td>
                  </tr>
                  <tr v-if="this.session.subscriptionData.expiresAfterDate">
                    <td>Expires after date</td>
                    <td>
                      {{ this.session.subscriptionData.expiresAfterDate }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SubscriptionHold // SubscriptionExpirationHold -->
            <div
              v-if="this.session.serviceType === 'SubscriptionHold' || this.session.serviceType === 'SubscriptionExpirationHold'"
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Subscription details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid
                  "
                  v-bind:href="getSubscriptionLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See subscription
                </a>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-if="
                      this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid &&
                      this.$root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td>
                      {{ this.session.subscriptionData.subscriptionGuid }}
                    </td>
                  </tr>
                  <tr>
                    <td>Hold description</td>
                    <td v-if="this.session.subscriptionData.holdDescription">
                      {{ this.session.subscriptionData.holdDescription }}
                    </td>
                    <td v-else>No description given</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SubscriptionCancel -->
            <div
              v-if="this.session.serviceType === 'SubscriptionCancel'"
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Subscription details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.session.subscriptionData &&
                    this.session.subscriptionData.subscriptionGuid
                  "
                  v-bind:href="getSubscriptionLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See subscription
                </a>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-if="
                      this.session.subscriptionData &&
                      this.session.subscriptionData.subscriptionGuid &&
                      this.$root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td>
                      {{ this.session.subscriptionData.subscriptionGuid }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cancel description</td>
                    <td v-if="this.session.subscriptionData.cancelDescription">
                      {{ this.session.subscriptionData.cancelDescription }}
                    </td>
                    <td v-else>No description given</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SignUpRecurring / SignUpRecurringByBs3rd / SubscriptionRestart / SubscriptionExpirationRestart -->
            <div
              v-if="
                this.session.paymentMethodData &&
                (this.session.serviceType === 'SignUpSingle' ||
                  this.session.serviceType === 'SignUpRecurring' ||
                  this.session.serviceType === 'SignUpRecurringByBs3rd' ||
                  this.session.serviceType === 'SignUpSingleByGiro3rd' ||
                  this.session.serviceType === 'SignUpRecurringByGiro3rd' ||
                  this.session.serviceType === 'SignUpSingleByInvoice3rd' ||
                  this.session.serviceType === 'SignUpRecurringByInvoice3rd' ||
                  this.session.serviceType === 'SignUpSingleByEAN3rd' ||
                  this.session.serviceType === 'SignUpRecurringByEAN3rd' ||
                  this.session.serviceType === 'SubscriptionRestart' ||
                  this.session.serviceType === 'SubscriptionExpirationRestart' )
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Payment method details</h3>
                <a
                  v-if="
                    this.$root.user.role === 'MerchantAdmin' &&
                    this.paymentSession.paymentMethodGuidResult
                  "
                  v-bind:href="getPaymentMethodLink"
                  target="_blank"
                  class="action get_single"
                >
                  <i class="dashboard-icon icon-view"></i>See payment method
                </a>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-if="
                      this.paymentSession.paymentMethodGuidResult &&
                      this.$root.user.role !== 'MerchantAdmin'
                    "
                  >
                    <td>Guid</td>
                    <td>
                      {{ this.paymentSession.paymentMethodGuidResult }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      this.session.paymentMethodData &&
                      this.session.paymentMethodData.paymentMethodType
                    "
                  >
                    <td>Type</td>
                    <td>
                      {{
                        this.session.paymentMethodData.paymentMethodType
                          | humanReadablePaymentMethodType
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- SignUpRecurring -->
            <div
              v-if="
                this.session.serviceType === 'SignUpRecurring' &&
                this.paymentSession.paymentGuidResult
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper">
                <h3>Payment details</h3>
              </div>
              <table class="uik-widget__table_details">
                <tbody>
                  <tr v-if="this.paymentSession.paymentGuidResult">
                    <td>Guid</td>
                    <td>
                      <a
                        v-if="this.$root.user.role === 'MerchantAdmin'"
                        v-bind:href="getPaymentLink"
                        target="_blank"
                        >{{ this.paymentSession.paymentGuidResult }}</a
                      >
                      <span v-else>
                        {{ this.paymentSession.paymentGuidResult }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {{ this.session.paymentData.oneOffAmount | formatAmount }}
                      {{ this.session.paymentData.currencyCode }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- MetaData -->
            <div
              v-if="
                this.session.metaData &&
                Object.keys(this.session.metaData).length > 0
              "
              class="uik-widget__wrapper"
            >
              <div class="uik-widget-title__wrapper"><h3>Meta data</h3></div>
              <table v-if="this.workflow" class="uik-widget__table_details">
                <tbody>
                  <tr
                    v-for="metaDataField in this.session.metaDataFields"
                    v-bind:key="metaDataField.uuid"
                  >
                    <td>{{ metaDataField.label }}</td>
                    <td v-if="metaDataField.value">
                      {{ metaDataField.value }}
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="session"
      v-on:click="closeSessionModal()"
      class="of-modal-bg fadeIn"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";
import { workflowHandler } from "../../mixins/workflowHandler.js";
import { campaignHandler } from "../../mixins/campaignHandler.js";
import { userHandler } from "../../mixins/userHandler.js";
import { relayHandler } from "../../mixins/relayHandler.js";
import { formats } from "../../mixins/formats.js";

export default Vue.extend({
  name: "SessionModal",
  mixins: [
    workflowHandler,
    campaignHandler,
    userHandler,
    relayHandler,
    formats,
  ],
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      campaign: {},
      workflow: {},
      user: {},
      paymentSession: {},
    };
  },
  mounted() {
    //  Hotkey to close modal by Esc
    let self = this;
    window.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        self.closeSessionModal();
      }
    });
  },
  created() {
    this.getSessionWorkflow();
    this.getSessionCampaign();
    this.getSessionUser();
    this.getSessionPaymentSession();
  },
  beforeUpdate() {
    this.parseMetaData();
  },
  computed: {
    /**
     * Get Payment OFv4 link
     */
    getPaymentLink() {
      return (
        window.location.origin +
        this.$root.backendPath +
        "?page=payments&payment=" +
        this.paymentSession.paymentGuidResult
      );
    },

    /**
     * Get PaymentMethod OFv4 link
     */
    getPaymentMethodLink() {
      return (
        window.location.origin +
        this.$root.backendPath +
        "?page=payment_methods&payment_method=" +
        this.paymentSession.paymentMethodGuidResult
      );
    },

    /**
     * Get Subscription OFv4 link
     */
    getSubscriptionLink() {
      //  Session
      if (
        this.session.subscriptionData &&
        this.session.subscriptionData.subscriptionGuid
      ) {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=subscriptions&subscription=" +
          this.session.subscriptionData.subscriptionGuid
        );
      }

      //  PaymentSession
      else {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=subscriptions&subscription=" +
          this.paymentSession.subscriptionGuidResult
        );
      }
    },

    /**
     * Get AddOn OFv4 link
     */
    getAddOnLink() {
      //  Session
      if (
        this.session.subscriptionData &&
        this.session.subscriptionData.subscriptionGuid
      ) {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=subscriptions&subscription=" +
          this.session.subscriptionData.subscriptionGuid +
          "&add_on=" +
          this.session.addOnData[0].addOnGuid
        );
      }

      //  PaymentSession
      else {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=subscriptions&subscription=" +
          this.paymentSession.subscriptionGuidResult +
          "&add_on=" +
          this.session.addOnData[0].addOnGuid
        );
      }
    },

    /**
     * Get Agreement OFv4 link
     */
    getAgreementLink() {
      //  Session
      if (this.session.agreementData.agreementGuid) {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=agreements&agreement=" +
          this.session.agreementData.agreementGuid
        );
      }

      //  PaymentSession
      else {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=agreements&agreement=" +
          this.paymentSession.agreementGuidResult
        );
      }
    },

    /**
     * Get Contact OFv4 link
     */
    getContactLink() {
      //  Session
      if (this.session.contactData.contactGuid) {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=contacts&contact=" +
          this.session.contactData.contactGuid
        );
      }

      //  PaymentSession
      else {
        return (
          window.location.origin +
          this.$root.backendPath +
          "?page=contacts&contact=" +
          this.paymentSession.contactGuidResult
        );
      }
    },

    /**
     * Get DataSet OFv4 link
     */
    getDataSetLink() {
      return (
        window.location.origin +
        this.$root.backendPath +
        "?page=datasets&dataset=" +
        this.session.dataSetGuid
      );
    },

    /**
     * Get Communication OFv4 link
     */
    getCommunicationLink() {
      return (
        window.location.origin +
        this.$root.backendPath +
        "?page=communications&communication=" +
        this.session.communicationCollectionGuid
      );
    },

    /**
     * Make schedule human readable
     */
    getHumanReadableSchedule() {
      return this.humanReadableSchedule(this.session.agreementData);
    },
  },
  methods: {
    /**
     * Close modal
     */
    closeSessionModal: function () {
      this.$emit("closeSessionModal");
    },

    /**
     * Get workflow
     */
    getSessionWorkflow: function () {
      //  Handle promise or storage
      let workflowHandler = this.getWorkflow(this.session.workflowGuid);

      //  Promise
      if (typeof workflowHandler.then !== "undefined") {
        workflowHandler.then((response) => {
          if (response.workflowErrored) {
            this.workflow = null;
          } else {
            this.workflow = response.workflow;
          }
        });
      }

      //  Storage
      else if (workflowHandler.workflow) {
        this.workflow = workflowHandler.workflow;
      }
    },

    /**
     * Get campaign
     */
    getSessionCampaign: function () {
      //  Handle promise or storage
      let campaignHandler = this.getCampaign(this.session.campaignGuid);

      //  Promise
      if (typeof campaignHandler.then !== "undefined") {
        campaignHandler.then((response) => {
          if (response.campaignErrored) {
            this.campaign = null;
          } else {
            this.campaign = response.campaign;
          }
        });
      }

      //  Storage
      else if (campaignHandler.campaign) {
        this.campaign = campaignHandler.campaign;
      }
    },

    /**
     * Get user
     */
    getSessionUser: function () {
      if (
        this.$root.user.role === "MerchantAdmin" ||
        this.$root.user.role === "AgencyManager" ||
        this.$root.user.role === "SeniorAgent"
      ) {
        this.getUser(this.session.userGuid);
      }
    },

    /**
     * Get payment session by sessionGuid
     */
    getSessionPaymentSession: function () {
      if (this.session.paymentSessionGuid) {
        //  Handle promise or storage
        let paymentSessionHandler = this.getPaymentSession(
          this.session.sessionGuid
        );

        //  Promise
        if (typeof paymentSessionHandler.then !== "undefined") {
          paymentSessionHandler.then((response) => {
            if (response.paymentSessionErrored) {
              this.paymentSession = {};
            } else {
              this.paymentSession = response.paymentSession;
            }
          });
        }

        //  Storage
        else if (paymentSessionHandler.paymentSession) {
          this.paymentSession = paymentSessionHandler.paymentSession;
        }
      }
    },

    /**
     * Parse meta data
     */
    parseMetaData: function () {
      var metaDataFields = [];

      //  Verify data
      if (this.session.metaData && this.workflow.metaDataFields) {
        //  Loop metaDataFields
        for (var i = 0; i < this.workflow.metaDataFields.length; i++) {
          //  Ensure value
          if (
            this.session.metaData.hasOwnProperty(
              this.workflow.metaDataFields[i].name
            )
          ) {
            //  Get value
            var value =
              this.session.metaData[this.workflow.metaDataFields[i].name];

            //  Set value
            metaDataFields.push({
              label: this.workflow.metaDataFields[i].label,
              name: this.workflow.metaDataFields[i].name,
              value: value,
            });
          }

          //  Default empty value
          else {
            //  Set value
            metaDataFields.push({
              label: this.workflow.metaDataFields[i].label,
              name: this.workflow.metaDataFields[i].name,
              value: null,
            });
          }
        }
      }

      //  Ensure unbinded object
      this.session.metaDataFields = JSON.parse(JSON.stringify(metaDataFields));
    },
  },
});
</script>
